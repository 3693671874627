import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';

const BASE_URL = 'http://selenoid.eastus2.cloudapp.azure.com:4444/download/';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    padding: '20px',
    margin: '0',
  },
  content: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  mainContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textField: { width: '200px', marginRight: '20px' },
  formRow: { display: 'flex', alignItems: 'center' },
}));

export const BotFiles = () => {
  const classes = useStyles();
  const [sessionId, setSessionId] = useState();
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState(null);

  const handleChange = (event) => {
    setSessionId(event.target.value);
  };

  const handleFetch = () => {
    parseLinks(`${BASE_URL}/${sessionId}`);
  };

  async function parseLinks(url) {
    setLoading(true);
    setLinks(null);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const html = await response.text();

      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      const linksArr = Array.from(doc.querySelectorAll('a'))
        .map((link) => link.href)
        .map((link) =>
          link.includes('/') ? link.split('/').reverse()[0] : link
        );

      setLinks(linksArr);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching or parsing the URL:', error);
      setLoading(false);
    }
  }

  // useEffect(() => { return () => {}; }, []);

  const tableConfig = [
    {
      heading: 'File Name',
      formatter: (val) => val,
    },
    {
      heading: 'Download Button',
      formatter: (val) => (
        <a href={`${BASE_URL}/${sessionId}/${val}`} download={val}>
          Download
        </a>
      ),
    },
  ];

  return (
    <div className={classes.wrapper}>
      <Paper elevation={1} className={classes.content}>
        <Typography variant="h6" className={classes.title}>
          Selenoid Bot File Download Utility
        </Typography>
        <section className={classes.formRow}>
          <TextField
            label="Session ID"
            variant="outlined"
            className={classes.textField}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleFetch}
          >
            Find Session Files
          </Button>
        </section>
        <section className={classes.mainContent}>
          {loading && <CircularProgress />}
          {links && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {tableConfig.map((cfg, i) => (
                    <TableCell>
                      <Typography
                        variant="overline"
                        className={classes.cardHead}
                      >
                        {cfg.heading}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {links?.map((val, j) => (
                  <TableRow key={val}>
                    {tableConfig.map((cfg, i) => (
                      <TableCell
                        className={classes.smallRow}
                        key={`row${j}-${cfg.heading}`}
                      >
                        {typeof cfg.formatter === 'function'
                          ? cfg.formatter(val)
                          : val}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </section>
      </Paper>
    </div>
  );
};
