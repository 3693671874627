import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';
import {
  Paper,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  Checkbox,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#eee', // TODO: get these from the actual theme
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  wrapper: {
    width: '100%',
    padding: '20px',
    margin: '0',
  },
  content: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  chartContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const tableConfig = [
  {
    heading: 'Oddity Detected',
    key: 'oddityDetection',
    formatter: (val) => val,
  },
  { heading: 'Process', key: 'process', formatter: (val) => val },
  {
    heading: 'Report Created',
    key: 'reportCreated',
    formatter: (val) => moment(val).format('MM/DD/YYYY, h:mma'),
  },
  {
    heading: 'Created',
    key: 'created',
    formatter: (val) => moment(val).format('MM/DD/YYYY'),
  },
  { heading: 'Partner', key: 'partner', formatter: (val) => val },
  {
    heading: 'Resolved',
    key: 'status',
    formatter: (val) => (
      <Checkbox
        color="primary"
        checked={val === 'Resolved'}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    ),
  },
  { heading: 'Owner', key: 'owner', formatter: (val) => val },
];

/*
 *  Accepts an object of key count pairs, and converts to an array of objects with name: key, value: count
 *  ex:
 *
 *  objectKeyPairToNameValueArray({bob: 23, bill: 55});
 *  // => [{name: bob, value: 23}, {name: bill, value: 55}];
 *
 * */
function objectKeyPairToNameValueArray(obj) {
  return Object.keys(obj).map((k) => ({ name: [k], value: obj[k] }));
}

export const OddityReports = () => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('created');
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // FIXME: fake data
  const data = [...new Array(50)].map((x, index) => {
    return {
      id: `asdflkj${index}`, // or GUID
      oddityDetection: 'Change in AUM',
      process: ['Imports', 'Trades', 'Fees', 'Status'][
        Math.floor(Math.random() * 4)
      ],
      reportCreated: new Date(),
      created: '01/22/2024',
      partner: ['OneAmerica', 'Insperity', 'Howard Simon', ''][
        Math.floor(Math.random() * 4)
      ],
      status: ['Resolved', 'In Progress', 'Unresolved'][
        Math.floor(Math.random() * 3)
      ],
      owner: ['Operations', 'Engineering - #CD3W', 'Engineering - #Cf3W'][
        Math.floor(Math.random() * 3)
      ],
    };
  });

  const statusCountObj = {};
  const typeCountObj = {};

  data.forEach((row) => {
    const statusCount = statusCountObj[row.status] || 0;
    const typeCount = typeCountObj[row.process] || 0;

    statusCountObj[row.status] = statusCount + 1;
    typeCountObj[row.process] = typeCount + 1;
  });

  const chartStatusData = [
    {
      name: 'In Progress',
      value: statusCountObj['In Progress'],
      fill: '#ffd966',
    },
    {
      name: 'Unresolved',
      value: statusCountObj['Unresolved'],
      fill: '#ff0001',
    },
    { name: 'Resolved', value: statusCountObj['Resolved'], fill: '#34a854' },
  ];

  const chartTypeData = [
    { name: 'Trades', value: typeCountObj['Trades'], fill: '#05b8d9' },
    { name: 'Imports', value: typeCountObj['Imports'], fill: '#c4e5eb' },
    { name: 'Status', value: typeCountObj['Status'], fill: '#ffd966' },
    { name: 'Fees', value: typeCountObj['Fees'], fill: '#182b4d' },
  ];

  return (
    <div className={classes.wrapper}>
      <Paper elevation={1} className={classes.content}>
        <Typography variant="h6" className={classes.title}>
          Oddity Reports
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              {tableConfig.map((cfg, i) => (
                <TableCell
                  className={classes.smallRow}
                  key={cfg.heading}
                  sortDirection={orderBy === cfg.key ? order : false}
                  onClick={() => handleRequestSort(cfg.key)}
                >
                  <Typography variant="overline" className={classes.cardHead}>
                    {cfg.heading}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, j) => (
                <TableRow key={row.id}>
                  {tableConfig.map((cfg, i) => (
                    <TableCell
                      className={classes.smallRow}
                      key={`row${j}-${cfg.heading}`}
                    >
                      {typeof cfg.formatter === 'function'
                        ? cfg.formatter(row[cfg.key])
                        : row[cfg.key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Paper elevation={1} className={classes.content}>
        <Typography variant="h6" className={classes.title}>
          Oddity Reports
        </Typography>
        <section className={classes.chartContent}>
          <PieChart width={500} height={400}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={chartStatusData}
              cx="50%"
              cy="50%"
              outerRadius={150}
            />
            <Tooltip formatter={(value, name, props) => `${name}: ${value}`} />
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              iconType="circle"
              iconSize="12"
            />
          </PieChart>
          <PieChart width={500} height={400}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={chartTypeData}
              cx="50%"
              cy="50%"
              outerRadius={150}
            />
            <Tooltip formatter={(value, name, props) => `${name}: ${value}`} />
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              iconType="circle"
              iconSize="12"
            />
          </PieChart>
        </section>
      </Paper>
    </div>
  );
};
